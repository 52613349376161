<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="text-secondary my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche d'équipe :
          </span>
          <span
            style="font-size: 25px; color: #343a40"
            class="font-weight-bold"
            >{{ selecteddata.name }}</span
          >
        </p>

        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="
            d-flex
            flex-wrap
            justify-content-between
            align-items-center
            mb-4
          "
        >
          <p
            class="h4 text-secondary my-auto mr-auto"
            style="margin: 6.656px 0 !important"
          >
            Informations générales
          </p>
          <div v-if="(isAdmin || isSuper) && editMode" class="m-0 d-flex p-0">
            <b-button type="submit" v-if="editMode" @click="updateitem" pill
              >Confirmer</b-button
            >
            <b-button
              style="
                background-color: rgb(173, 181, 189);
                border-color: rgb(173, 181, 189);
              "
              @click="editMode = !editMode"
              v-if="editMode"
              pill
              >Annuler</b-button
            >
            <a
              v-else
              href="#"
              class="text-secondary"
              style="font-size: 30px; color: #007bffff !important"
              @click.prevent="editMode = !editMode"
              ><i class="mdi mdi-file-document-edit"></i
            ></a>
          </div>
          <a
            v-else-if="isAdmin || isSuper"
            href="#"
            class="text-secondary"
            style="font-size: 30px; color: #007bffff !important"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <hr class="bg-secondary mb-3 mt-1 w-100" />
        </div>
        <div v-if="!editMode">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="my-3">
                <span>Libellé équipe : </span>
                <span class="font-weight-bold mr-3">{{
                  selecteddata.name
                }}</span>
              </div>
              <div class="my-3">
                <span>Responsable : </span>
                <span class="font-weight-bold mr-3">{{
                  selecteddata.manager
                    ? selecteddata.manager.firstname +
                      " " +
                      selecteddata.manager.lastname
                    : ""
                }}</span>
              </div>
              <div class="my-3">
                <span>Entité : </span>
                <span class="font-weight-bold mr-3">
                  {{
                    TEAM.organisation && TEAM.organisation.entite
                      ? TEAM.organisation.entite.libelle
                      : "-"
                  }}</span
                >
              </div>
              <div class="my-3">
                <span>Organisation de rattachement : </span>
                <span class="font-weight-bold mr-3">{{
                  selecteddata.organisation
                    ? selecteddata.organisation.libelle
                    : ""
                }}</span>
              </div>
              <div class="my-3">
                <span>Equipe de rattachement : </span>
                <span class="font-weight-bold mr-3">{{
                  TEAM.parent ? TEAM.parent.name : "-"
                }}</span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="my-3">
                <span>Type organisation : </span>
                <span class="font-weight-bold mr-3">{{
                  TEAM.organisation && TEAM.organisation.type
                    ? TEAM.organisation.type.libelle
                    : "-"
                }}</span>
              </div>
              <div class="my-3">
                <span>Niveau : </span>
                <span class="font-weight-bold mr-3">{{
                  TEAM.parent ? 2 : 1
                }}</span>
              </div>
              <div class="my-3">
                <p>Description :</p>
                <span class="font-weight-bold mr-3">{{
                  selecteddata.description
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                label="Libellé équipe :"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="newTeam.name"
                  type="text"
                  :class="{
                    'is-invalid':
                      $v.newTeam.name.$error && $v.newTeam.name.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTeam.name.$dirty"
                >
                  {{
                    !$v.newTeam.name.required
                      ? "Champ obligatoire"
                      : !$v.newTeam.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newTeam.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Responsable :"
                label-class="font-weight-bold"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="(user) => user.value"
                    v-model="newTeam.responsable"
                    :options="responsables"
                    placeholder="Responsable"
                    style="padding-right: 0 !important"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="Entité :"
                label-class="font-weight-bold"
              >
                <v-select
                  label="text"
                  :reduce="({ value }) => value"
                  :options="entitesList"
                  placeholder="Entité"
                  v-model="selectedEntite"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.selectedEntite.$error && $v.selectedEntite.$dirty,
                  }"
                  @input="
                    (newTeam.organisation = null),
                      (newTeam.parent = null),
                      (newTeam.niveau = 1)
                  "
                ></v-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedEntite.$dirty"
                >
                  {{ !$v.selectedEntite.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Organisation de rattachement :"
                label-class="font-weight-bold"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="(user) => user.value"
                    v-model="newTeam.organisation"
                    :options="organisations"
                    placeholder="Organisation"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newTeam.organisation.$error &&
                        $v.newTeam.organisation.$dirty,
                    }"
                    @input="
                      () => {
                        newTeam.type = newTeam.organisation
                          ? ORGANISATIONS.find(
                              (orga) => orga['@id'] === newTeam.organisation
                            ).type
                          : null;
                        newTeam.parent = null;
                        newTeam.niveau = 1;
                      }
                    "
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>

                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newTeam.organisation.$dirty"
                  >
                    {{
                      !$v.newTeam.organisation.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Equipe de rattachement :"
                label-class="font-weight-bold"
              >
                <v-select
                  label="text"
                  :reduce="({ value }) => value"
                  :options="teamsList"
                  type="text"
                  placeholder="Equipe de rattachement"
                  v-model="newTeam.parent"
                  style="padding-right: 0 !important"
                  @input="newTeam.niveau = newTeam.parent ? 2 : 1"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </b-form-group>
            </div>

            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                label="Type organisation :"
                label-class="font-weight-bold"
              >
                <v-select
                  :options="ORG_TYPES"
                  placeholder="Type organisation"
                  v-model="newTeam.type"
                  disabled
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newTeam.type.$error && $v.newTeam.type.$dirty,
                  }"
                ></v-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTeam.type.$dirty"
                >
                  {{ !$v.newTeam.type.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Niveau :"
                label-class="font-weight-bold"
              >
                <b-form-input
                  placeholder="Niveau"
                  type="number"
                  v-model="newTeam.niveau"
                  disabled
                  style="padding-right: 0 !important"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Description :"
                label-class="font-weight-bold"
              >
                <div class="W-50">
                  <textarea
                    v-model="newTeam.description"
                    placeholder="Description"
                    class="form-control W-50"
                    id="exampleFormControlTextarea1"
                    rows="6"
                  ></textarea>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="d-flex mb-2">
        <span class="h4 text-secondary mr-auto my-auto"
          >Liste des collaborateurs
        </span>

        <div class="d-flex justify-content-end">
          <b-button
            style="
              background-color: #007bffff !important;
              border-color: #007bffff !important;
            "
            :disabled="!(isAdmin || isSuper)"
            variant="secondary"
            class="align-btn-table-col"
            @click="display"
            ><i class="mdi mdi-plus"></i> Ajouter un collaborateur
          </b-button>
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            :items="members.members"
            :fields="collaborators.tableFields"
            :per-page="perPage"
            :busy="loading"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            responsive
            show-empty
            bordered
            striped
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(full_name)="data">
              <span>{{ data.item.firstname + " " + data.item.lastname }}</span>
            </template>
            <template v-slot:cell(function)="data">
              <span>{{ data.value ? data.value : "-" }}</span>
            </template>
            <template v-slot:cell(email)="data">
              <span>{{ data.value }}</span>
            </template>
            <template v-slot:cell(organisation_attachment)>
              <b-link
                v-if="selecteddata.organisation"
                :to="{
                  name: 'show-organisation',
                  params: {
                    slug: selecteddata.organisation.slug
                      ? selecteddata.organisation.slug
                      : '61f1159652a44',
                    id: selecteddata.organisation.id,
                  },
                }"
                >{{
                  selecteddata.organisation
                    ? selecteddata.organisation.libelle
                    : "-"
                }}</b-link
              >
              <span v-else>-</span>
            </template>
            <template v-slot:cell(isActif)="data">
              <b-badge v-if="data.value" variant="success">Actif</b-badge>
              <b-badge v-else variant="danger">Inactif</b-badge>
            </template>
            <template v-slot:cell(actions)="data">
              <table-actions
                class="justify-content-center"
                :actions="isAdmin || isSuper ? ['delete'] : []"
                @deleteItem="deleteTeam(data.item.id)"
              />
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
      </div>
    </b-card>

    <div v-if="addCollaborators" to="modals-xyz-548">
      <Modal @close="addCollaborators = !addCollaborators">
        <add-collaborators
          :collaborateurs="datacoll"
          :name="selecteddata.name"
          @close="close"
          :existedCollaborators="members.members"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import TableActions from "@/components/custom/TableActions.vue";
import Modal from "@/components/custom/Modal.vue";
import AddCollaborators from "../components/AddCollaborators.vue";
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { USER_URL } from "@/helpers/constURL";

export default {
  components: {
    ModalActions,
    TableActions,
    Modal,
    AddCollaborators,
  },
  data: () => ({
    editMode: false,
    sortBy: "full_name",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    selectedColl: [],
    showActeurs: false,
    addCollaborators: false,
    selectedresponsable: [],
    members: [],
    loading: true,
    description: "",
    datacoll: null,
    name: "",
    newTeam: {
      name: "",
      responsable: [],
      description: "",
      organisation: "",
      parent: null,
      type: "",
      niveau: 1,
    },
    selectedEntite: null,
    organisation: [],
    selecteddata: [],
    actions: ["delete"],
    collaborators: {
      tableFields: [
        { key: "full_name", label: "Nom complet", sortable: true },
        { key: "email", label: "Mail", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "function", label: "Fonction", sortable: true },
        { key: "isActif", label: "Compte", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
  }),
  validations: {
    newTeam: {
      name: {
        required,
        minLength: minLength(5),
      },
      organisation: {
        required,
      },
      type: {
        required,
      },
    },
    selectedEntite: {
      required,
    },
  },

  watch: {
    TEAM() {
      this.selecteddata = this.TEAM;
      this.newTeam.responsable = this.TEAM.manager
        ? this.TEAM.manager["@id"]
        : "";
      this.newTeam.organisation = this.TEAM.organisation
        ? this.TEAM.organisation["@id"]
        : "";
      this.newTeam.name = this.TEAM.name ? this.TEAM.name : "";
      this.newTeam.description = this.TEAM.description
        ? this.TEAM.description
        : "";
      this.TEAM.organisation.entite = this.ORGANISATIONS.find(
        (orga) => orga["@id"] === this.newTeam.organisation
      ).entite;
      this.selectedEntite =
        this.TEAM.organisation && this.TEAM.organisation.entite
          ? this.TEAM.organisation.entite.id
          : null;
      this.newTeam.niveau = this.TEAM.niveau;
      this.newTeam.parent = this.TEAM.parent ? this.TEAM.parent["@id"] : null;
      this.newTeam.type = this.newTeam.organisation
        ? this.ORGANISATIONS.find(
            (orga) => orga["@id"] === this.newTeam.organisation
          ).type
        : null;
    },
    MEMBERS() {
      this.members = this.MEMBERS;
    },
  },

  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations").then(() => {
      this.$store
        .dispatch("teams/fetchTeam", this.$route.params.id)
        .then(() => {
          this.$store.dispatch("loader/toggleLoading", false);
        });
    });
    this.$store
      .dispatch("teams/fetchMembers", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
    this.$store.dispatch("teams/fetchAllTeams");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("type/fetchOrganisationTypes");
  },

  computed: {
    ...mapGetters("teams", ["TEAM", "TEAMS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("teams", ["MEMBERS"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("type", ["ORG_TYPES"]),

    rows() {
      return this.members.members ? this.members.members.length : 0;
    },

    responsables() {
      return this.RESPONSABLES.filter(
        (responsable) =>
          !this.TEAMS.find(
            ({ manager, id }) =>
              id !== this.TEAM.id && manager.id === responsable.id
          )
      ).map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    entitesList() {
      return this.ENTITIES.map((entity) => ({
        value: entity.id,
        text: entity.libelle,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.filter((organisation) =>
        this.selectedEntite && organisation.entite
          ? parseInt(organisation.entite.id) === parseInt(this.selectedEntite)
          : false
      ).map((org) => ({
        value: org["@id"],
        text: org.libelle,
      }));
    },
    teamsList() {
      return this.newTeam.organisation
        ? this.TEAMS.filter(
            (team) =>
              team.organisation["@id"] === this.newTeam.organisation &&
              team.niveau === 1
          ).map((team) => ({
            value: team["@id"],
            text: team.name,
          }))
        : [];
    },
  },
  methods: {
    close() {
      this.$store
        .dispatch("teams/fetchTeam", this.$route.params.id)
        .then(() => {
          this.$store
            .dispatch("teams/fetchMembers", this.$route.params.id)
            .then(() => {
              this.addCollaborators = !this.addCollaborators;
            });
        });
    },
    deleteTeam: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var datateam = this.members.members
            .filter((item) => item.id !== id)
            .map((data) => data["@id"]);
          datateam = {
            id: this.$route.params.id,
            members: datateam,
          };
          this.$store.dispatch("teams/updateTeam", datateam).then(() => {
            this.members.members = this.members.members.filter(
              (item) => item.id !== id
            );
            this.editMode = false;
            this.$store
              .dispatch("teams/fetchTeam", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    display() {
      if (this.isAdmin || this.isSuper)
        this.$store
          .dispatch("teams/fetchMembers", this.$route.params.id)
          .then(() => {
            this.datacoll = this.MEMBERS.members;
            this.addCollaborators = !this.addCollaborators;
          });
    },
    getorganisation() {
      return this.selecteddata.organisation.libelle;
    },
    updateitem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          id: this.$route.params.id,
          name: this.newTeam.name,
          manager: this.newTeam.responsable,
          organisation: this.newTeam.organisation,
          parent: this.newTeam.parent,
          description: this.newTeam.description,
        };
        this.$store.dispatch("teams/updateTeam", item).then(() => {
          this.editMode = false;
          this.$store.dispatch("teams/fetchTeam", item.id).then(() => {
            Swal.fire({
              title: "L'équipe est mise a jour !",
              type: "success",
              confirmButtonText: "OK",
            });
          });
        });
      }
    },
  },
};
</script>

<style></style>
